let hostname = window.location.hostname;
export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : `https://${hostname}`;

// PUBLIC ENDPOINTS
export const USER_LOGIN_URL = `/auth/login/`;
export const USER_GOOGLE_LOGIN_URL = '/auth/social/google-oauth2/';
export const USER_REGISTRATION_URL = `/auth/registration/`;
export const USER_LOGOUT_URL = `/auth/logout/`;
export const USER_PASS_FORGET_URL = `/auth/password/reset/`;
export const USER_PASS_RESET_URL = `/auth/password/reset/confirm/`;
export const USER_DETAILS_URL = `/api/v1/user`;
export const USER_DEVICES_URL = `/api/v1/devices`;
export const USER_STORE_URL = `/api/v1/user/stores`;

export const INVENTORY_URL = (uuid) => {
  return `/api/v1/inventory/${uuid}`;
};
export const INVENTORY_ITEM_URL = (item, uuid) => {
  return `/api/v1/inventory/item/${uuid}/${item}`;
};
export const SEARCH_ITEM_URL = (uuid, keywords) => {
  return `/api/v1/inventory/${uuid}/search/${keywords}`;
};
export const INVENTORY_REMOVE_ITEM_URL = (item) => {
  return `/api/v1/inventory/remove/${item}`;
};
export const DEPARTMENT_LIST_URL = (uuid) => {
  return `/api/v1/inventory/${uuid}/departments`;
};
export const INVENTORY_ADD_UPDATE_URL = `/api/v1/inventory/add-update/`;

export const USER_STORE_UPDATE_URL = (id) => {
  return `/api/v1/user/stores/${id}/update/`;
};
export const USER_STORE_ONBOARD_URL = (uuid) => {
  return `/api/v1/store/${uuid}/onboard`;
};

export const USER_STORE_PAYMENT_URL = (uuid) => {
  return `/api/v1/store/${uuid}/pay`;
};

export const MENU_SYNC_URL = (uuid) => {
  return `/api/v1/sync/item/${uuid}`;
};

export const USER_POS_DELETE_URL = (uuid) => {
  return `/api/v1/pos/delete/${uuid}`;
};
// For testing purpose only
export const TEST_ORDER_HOOK = `/api/v1/order/test/`;
export const ORDER_HOOK = `/api/v1/order/hook/`;

// TOKENS
export const REFRESH_TOKEN_URL = `/auth/token/refresh/`;

// LOCALSTORAGE & DEV KEYS
export const DEV_AUTH_TOKEN_KEY = 'test_dev_auth';
export const DEV_REFRESH_TOKEN_KEY = 'test_dev_ref';
export const LS_POS_LIST = 'LS_POS';

// APP VERSION
export const APP_VERSION = '0.0.1a';
